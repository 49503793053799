import React from "react";
import "./style/Home.scss";
import DoubleLayout from "../components/DoubleLayout";
import Tags from "../components/Tags";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="home-container">
      <div className="personal-details-container">
        <picture className="hero-image-wrapper">
          <img
            className="hero-image"
            src="./images/smitesh-tamboli.png"
            alt="Smitesh Tamboli"
          />
        </picture>
        <div className="personal-details-wrapper">
          <h1>
            Hi, I'm <span>Smitesh</span>
          </h1>
          <h3>
            Creative Software Developer Building Smart Solutions With Modern
            Technology
          </h3>
          <div className="social-links-wrapper">
            <a
              href="https://www.linkedin.com/in/smitesh-tamboli/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className="fa-icon" size="25" />
            </a>
            <a
              href="https://github.com/smitesht"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub className="fa-icon" size="25" />
            </a>
          </div>
        </div>
      </div>
      <div className="skills-container">
        <div className="grid-cell">
          <img src="./images/C++.png" alt="C++" />
          <p className="skills">
            C++, Modern C++, STL, Design Patterns, Data Structure and Algorithm
          </p>
        </div>
        <div className="grid-cell">
          <img src="./images/AI.png" alt="Artificial Intellegence" />
          <p className="skills">
            Python, Machine Learning, CNN, RNN, Data Analytics, Webscraping
          </p>
        </div>
        <div className="grid-cell">
          <img src="./images/Dbs.png" alt="Databases" />
          <p className="skills">MySQL, SQL Server, MongoDB</p>
        </div>
        <div className="grid-cell">
          <img src="./images/tools.png" alt="Tools" />
          <p className="skills">GitHub, C#.Net Framework, React.JS</p>
        </div>
      </div>
      <div className="experience-container">
        <div className="experience-wrapper">
          <h3 className="title">Experience</h3>
          <p className="experience-info">
            I have more than 15 years of experience in software development, and
            worked with many MNCs.
          </p>
          <ul className="experience-points">
            <li>
              <strong>GAVS Technology</strong> - Project Lead
            </li>
            <li>
              <strong>Saskatchewan Polytechnic</strong> - Student Research
              Assistant (Canada)
            </li>
            <li>
              <strong>Great Software Laboratory</strong> - Lead Software
              Engineer
            </li>
            <li>
              <strong>Harman Technologies</strong> - Product Architect
            </li>
            <li>
              <strong>Honeywell</strong> - Senior Engineer
            </li>
            <li>
              <strong>GlobalGraphics</strong> - Senior Software Engineer
            </li>
            <li>
              <strong>KPIT</strong> - Software Engineer
            </li>
            <li>
              <strong>EnCircle Exports</strong> - Software Engineer
            </li>
          </ul>
        </div>
        <picture className="image-wrapper">
          <img src="./images/smitesh-experience.png" alt="Experience" />
        </picture>
      </div>
      <div className="education-container">
        <picture className="image-wrapper">
          <img src="./images/smitesh-education.png" alt="Education" />
        </picture>
        <div className="education-wrapper">
          <h3 className="title">Education</h3>
          <ul className="education-points">
            <li>
              <strong>Saskatchewan Polytechnic (Regina, Canada)</strong> - PG
              Certificate in AI and Data Analytics (Jan 2022 - Dec 2022)
            </li>
            <li>
              <strong>IGNOU</strong> - Master's in Computer Applications
            </li>
            <li>
              <strong>M.S.University (Vadodara)</strong> - B.Sc. (Statistics)
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Home;
